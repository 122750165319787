import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Studenflak({ data }) {
  const title = "Studentflak - Allt du behöver veta";
  return (
    <>
      <Page title={title}>
        <article className="container px-5 py-6 mx-auto info-content">
          <iframe
            className="mb-4 rounded-lg"
            width="100%"
            height="500"
            src="https://www.youtube.com/embed/LLAcSdBTrcc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <h1 className="text-4xl text-gray-900 font-bold md:text-5xl">
            {title}
          </h1>
          <p>
            Studentflaket är en stor del av studentfirandet under dagen. Tiden
            som studenterna åker med studentflaket varierar från skola till
            skola. En del gör det innan de har sprungit ut, medan andra kommer
            att göra det efter utspringet.
          </p>
          <p>
            För att sammanfatta det så är studentflaket en riktig rolig del av
            studentfirandet och något som alla borde uppleva. Studentflaket
            innebär massor av musik, dans och firandet att man nyss har tagit
            studenten.
          </p>
          <p>
            I denna artikeln kommer vi gå igenom allt som du behöver veta om
            studentflak.
          </p>
          <h2 id="vad-kostar-det-att-hyra-ett-studentflak-">
            Vad kostar det att hyra ett studentflak?
          </h2>
          <p>
            När man bokar studentflak så kan det tyckas vara rätt så dyrt. Ett
            studentflak för 60 personer kostar vanligtvis omkring 20 000 kronor
            om man även vill ha med ett ljudpaket med större högtalare och annat
            som ni behöver.
          </p>
          <p>
            Ett studentflak för 60 personer går utmärkt att boka för två klasser
            och om man räknar med att det går ungefär 25 personer i varje klass
            kommer kostnaden per person bli 800 kronor per person.
          </p>
          <p>
            Det finns också flak som kostar betydligt mer och de flaken för 200
            personer kostar vanligtvis uppemot 50 000 kronor.
          </p>
          <p>
            När du bokar studentflak så finns det många olika aktörer på
            marknaden och vilka som du kan välja beror på vad du bor. I Göteborg
            och i närliggande kommuner finns exempelvis 123Student, medan i
            Stockholm hittar du andra aktörer såsom MinStudent och
            Studentflakstockholm.
          </p>
          <p>
            Ett tips är att googla runt lite för att hitta det billigaste och
            bästa alternativet för dig och din klass beroende på vart i Sverige
            som ni bor.
          </p>
          <h2 id="vad-ing-r-n-r-du-bokar-studentflak-">
            Vad ingår när du bokar studentflak?
          </h2>
          <p>
            När man bokar studentflaket så brukar man vanligtvis behöva välja
            till saker såsom ljudpaket, dekorationer och om man behöver vill ha
            en flakvärd genom bolaget som man bakom genom eller inte.
          </p>
          <p>
            För många studentbolag så finns det även möjligheten att välja DJ
            när man bokar studentflak, men det är inte något som ingår i priset
            som standard och något som vi inte tycker att du behöver.
          </p>
          <p>
            Det är mycket enklare och billigare att skapa en egen musiklista på
            exempelvis Spotify som ni sätta ihop tillsammans med er klass.
          </p>
          <p>
            En flakvärd är däremot något som du behöver på ditt studentflak, och
            det är en person som hjälper till på flaket vid färden och kan
            fungera som chaufförens högra arm.
          </p>
          <p>
            En flakvärd kan vara en person som ni väljer och det är inte något
            du måste boka via företaget, men det som du behöver tänka på är att
            flakvärden vanligtvis ska vara minst 25 år.
          </p>
          <p>
            När du räknar antalet personer som får plats på flaket brukar inte
            heller flakvärden räknas med vilket kan vara bra att veta.
          </p>
          <h2 id="f-r-man-dricka-alkohol-p-studentflaket-">
            Får man dricka alkohol på studentflaket?
          </h2>
          <p>
            Även om de flesta som tar studenten är 18 eller 19 år, vilket
            innebär att det är tillåtet för dem att dricka alkohol, så kommer de
            inte få dricka alkohol på studentflaket. För att dricka på
            studentflaket så man måste man vara 20 år.
          </p>
          <p>
            Detta beror på att det finns svensk lagstiftning som säger att man
            måste vara 20 år för att få dricka och ta med sig alkohol på
            studentflaket. Om du är minst 18 år kan du däremot konsumera
            alkoholhaltiga drycker som är under 3,5%, vilket brukar innebära
            folköl.
          </p>
          <p>
            Studentflaket brukar därför innebära en paus från drickandet av
            alkohol, men det betyder inte att festen måste ta slut. Det finns
            många andra sätt att ha roligt utan alkohol.
          </p>
        </article>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "studentguide.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
